var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[(_vm.isLoading)?_c('Loading'):_c('div',[_c('h1',{staticClass:"text-2xl font-bold"},[_vm._v("Form Response Portal")]),_c('div',{staticClass:"mt-14"},[_c('div',{staticClass:"w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2 mx-auto"},[_c('div',{staticClass:"w-full border-2 drop-shadow-sm border-gray-100 flex flex-col bg-white rounded-lg overflow-hidden cursor-pointer"},[_c('div',{staticClass:"border-t border-gray-200 p-5"},[_c('p',{staticClass:"font-medium mt-2 text-sm"},[_vm._v("Form Responses")]),_c('p',{staticClass:"text-xs"},[_vm._v("Click on cards to see detailed responses")]),_c('div',{staticClass:"border-2 border-gray-50 drop-shadow-2xl rounded-md cursor-pointer hover:bg-blue-300 hover:bg-opacity-25 hover:rounded-md p-3 mt-5",on:{"click":function($event){return _vm.$router.push({
                  name: 'SimManagement',
                })}}},[_c('p',{staticClass:"font-medium text-xs"},[_vm._v("SIM Management")]),_c('p',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.totalSimManagement)+" Responses")])]),_c('div',{staticClass:"border-2 border-gray-50 drop-shadow-2xl rounded-md cursor-pointer hover:bg-blue-300 hover:bg-opacity-25 hover:rounded-md p-3 mt-5",on:{"click":function($event){return _vm.$router.push({
                  name: 'SupportDeployment',
                })}}},[_c('p',{staticClass:"font-medium text-xs"},[_vm._v("Support Deployment")]),_c('p',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.totalSupportDeployment)+" Responses")])]),_c('div',{staticClass:"border-2 border-gray-50 drop-shadow-2xl rounded-md cursor-pointer hover:bg-blue-300 hover:bg-opacity-25 hover:rounded-md p-3 mt-5",on:{"click":function($event){return _vm.$router.push({
                  name: 'RetrievalResponse',
                })}}},[_c('p',{staticClass:"font-medium text-xs"},[_vm._v("Retrieval")]),_c('p',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.totalRetrievalResponse)+" Responses")])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }